import axios from 'axios'

const request = (
  url,
  setData,
  setState=()=>{},
  headers={'accessToken': localStorage.getItem('accessToken')}
) => {
  setState('loading')

  const source = axios.CancelToken.source()
  axios.get(url, {
    headers: headers,
    cancelToken: source.token
  })
  .then(response => {
    if (response.data.code === '80') {
      getAccessToken()
    } else {
      setState('ok')
      setData(response.data)
    }
  })
  .catch(err => {
    if (!axios.isCancel(err))
      setState('error')
  })

  return () => source.cancel()
}

const getAccessToken = () => {
  request(
    'https://openapi.emtmadrid.es/v2/mobilitylabs/user/login/',
    data => localStorage.setItem('accessToken', data.data[0].accessToken),
    undefined,
    {
      'passkey': 'C3D0E659D8D397782B414AB6FCC477B5C727435FE91E72069D34CEBB2C1491B3B8563FDAC043EA704660C0E87E6FE503C39D38FF43F7447563B1E437B349ACEC',
      'x-clientid': '8ff527f9-f85b-45ef-b1b2-bd9eb59e0fff'
    }
  )
}

if (!localStorage.getItem('accessToken')) {
  getAccessToken()
}

export default request
