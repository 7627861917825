import { Polyline, CircleMarker } from 'react-leaflet'

const Routes = ({routes}) =>
  routes.map(route =>
    <>
      <Polyline
        key={route.name}
        positions={route.route}
        color={route.color}
        stroke={5}
      />

      {route.stops.map(stop =>
        <CircleMarker
          center={stop}
          color={route.color}
          radius={2}
          fillOpacity={1}
        />
      )}
    </>
  )

export default Routes
