import { Form, Tag } from 'react-bulma-components'

const RadiusSlider = ({radius, setRadius, isSelecting}) =>
  <Form.Field kind='group'>
    <Form.Control>
      <input
        type='range'
        min={50}
        max={1000}
        step={10}
        value={radius}
        disabled={!isSelecting}
        onChange={e => setRadius(e.target.value)}
      />
    </Form.Control>
    <Form.Control>
      <Tag color={isSelecting ? 'white' : 'light'}>
        {radius}m
      </Tag>
    </Form.Control>
  </Form.Field>

export default RadiusSlider
