import { Button, Icon } from 'react-bulma-components'
import { MdDirectionsBus, MdClose } from 'react-icons/md'

const SearchButton = ({isSelecting, onClick}) =>
  <Button
    size='large'
    color={isSelecting ? 'link' : 'danger'}
    style={{borderRadius: '50%'}}
    onClick={onClick}
  >
    <Icon>
      {isSelecting ? <MdDirectionsBus/> : <MdClose/>}
    </Icon>
  </Button>

export default SearchButton
