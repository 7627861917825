import { MapContainer, Marker, TileLayer, Circle } from 'react-leaflet'
import { useState, useEffect, useCallback } from 'react'
import { DivIcon } from 'leaflet'
import 'leaflet/dist/leaflet.css'

import Routes from './components/Routes'
import SearchButton from './components/SearchButton'
import SearchBar from './components/SearchBar'
import RadiusSlider from './components/RadiusSlider'
import request from './helpers/request'
import deduplicate from './helpers/deduplicate'
import randomColor from 'randomcolor'

const App = () => {
  const [pos, setPos] = useState({lat: 40.419, lng: -3.693})
  const [radius, setRadius] = useState(200)
  const [isSelecting, setIsSelecting] = useState(true)
  const [map, setMap] = useState(null)

  const onMove = useCallback(() => {
    if (isSelecting) {
      setPos(map.getCenter())
    }
  }, [map, isSelecting])

  useEffect(() => {
    map?.on('move', onMove)
    return () => {
      map?.off('move', onMove)
    }
  }, [map, onMove])


  const [stops, setStops] = useState([])
  const searchStops = () => {
    if (isSelecting) {
      request(
        `https://openapi.emtmadrid.es/v2/transport/busemtmad/stops/arroundxy/${pos.lng}/${pos.lat}/${radius}/`,
        data => {
          setStops(data.data ? data.data : [])
        }
      )
    } else {
      setStops([])
      setPos(map.getCenter())
    }
    setIsSelecting(!isSelecting)
  }

  const [lines, setLines] = useState([])
  useEffect(() => {
    setLines(
      deduplicate(
        stops.map(stop => stop.lines).flat(),
        line => line.line+line.to
      )
    )
  }, [stops])

  const [routes, setRoutes] = useState([])
  useEffect(() => {
    setRoutes([])
    for (const number of deduplicate(lines.map(line => line.line))) {
      request(
        `https://openapi.emtmadrid.es/v1/transport/busemtmad/lines/${number}/route/`,
        data => {
          for (const to of lines
            .filter(line => line.line === number)
            .map(line => line.to)
          ) {
            setRoutes(prev => [
              ...prev,
              {
                'number': number+to,
                'route': data
                         .data
                         .itinerary[`to${to}`]
                         .features
                         .map(feature => feature.geometry.coordinates[0])
                         .flat()
                         .map(coords => coords.reverse()),
                'color': randomColor({seed: number*10}),
                'stops': data
                         .data
                         .stops[`to${to}`]
                         .features
                         .map(feature => feature.geometry.coordinates)
                         .map(coords => coords.reverse())
              }
            ])
          }
        }
      )
    }
  }, [lines])

  return (<>
    <MapContainer
      center={[40.419, -3.693]}
      zoom={14}
      zoomControl={false}
      style={{height: '100vh'}}
      ref={setMap}
    >
      <TileLayer url='https://api.maptiler.com/maps/bright-v2/256/{z}/{x}/{y}{r}.png?key=62fWxjuKZdoP6vlFjq0a' />

      <Circle center={pos} radius={radius} />

      {stops.map(
        stop => <Marker
                  key={stop.stopId}
                  position={stop.geometry.coordinates.reverse()}
                  icon={new DivIcon({html: '📍'})}
                />
      )}

      <Routes routes={routes} />
    </MapContainer>

    <div style={{position: 'absolute', top: 0, zIndex: 400, margin: 20}}>
      <SearchBar map={map} />
    </div>

    <div style={{position: 'absolute', bottom: 40, left: 20, zIndex: 400}}>
      <RadiusSlider
        radius={radius}
        setRadius={setRadius}
        isSelecting={isSelecting}
      />
    </div>

    <div style={{position: 'absolute', bottom: 40, right: 30, zIndex: 400}}>
      <SearchButton
        isSelecting={isSelecting}
        onClick={searchStops}
      />
    </div>

  </>)
}

export default App
