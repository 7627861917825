import { Button, Form, Icon } from 'react-bulma-components'
import { MdSearch, MdMyLocation } from 'react-icons/md'
import { useState } from 'react'

const SearchBar = ({ map }) => {
  const [search, setSearch] = useState('')

  const searchAddress = () =>
    fetch(`https://cartociudad.es/geocoder/api/geocoder/find?q=${search}, Madrid`)
    .then(data => data.json())
    .then(data =>
      map.setView({
        lat: data.lat,
        lng: data.lng
      })
    )
    .catch(() => {})

  const getLocation = () =>
    navigator.geolocation.getCurrentPosition(pos =>
      map.setView({
        lat: pos.coords.latitude,
        lng: pos.coords.longitude
      })
    )

  return (
    <Form.Field kind='group' size='medium'>
      <Form.Control fullwidth>
        <Form.Input
          placeholder='calle nsq'
          value={search}
          onChange={e => setSearch(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              searchAddress()
          }}
        />
      </Form.Control>
      <Form.Control>
        <Button onClick={searchAddress}>
          <Icon>
            <MdSearch/>
          </Icon>
        </Button>
      </Form.Control>
      <Form.Control>
        <Button onClick={getLocation}>
          <Icon>
            <MdMyLocation/>
          </Icon>
        </Button>
      </Form.Control>
    </Form.Field>
  )
}

export default SearchBar
